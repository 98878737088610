import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { required, email, alpha } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This question is required.",
});

extend("fieldRequired", {
  ...required,
  message: "{_field_} is required.",
});

extend("email", email);
extend("alpha", alpha);

extend("numberOnly", {
  validate(v) {
    return /^[0-9]*$/.test(v);
  },

  message: "Numbers only please",
});

const formStep = {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  
  methods: {
    ...mapGetters(["getAnswers","getUserId" , "getotherAnswer"]),
    ...mapActions(["nextStep", "prevStep", "updateAnswer","updateOtherAnswer"]),
    getNext(value) {
      this.nextStep(value);
    },
    getPrev(value, step_name) {
      this.$gtm.trackEvent({
        action: "click",
        event: "back_click",
        custom_uuid:this.getUserId(),
        virtual_page_url: `https://${window.location.host}/virtual/${step_name}`
      })
      this.prevStep(value);
    },
  },
  mounted() {
    this.isOther =  false;
    this.formData = this.getAnswers()? this.getAnswers():{};
      this.QuestionWithoutQB?.forEach(question => {
        if (question.type === 'order-select') {
          let orderSelectValues = question.values;
          orderSelectValues.forEach(orderselectvalue => {
            if (this.formData && this.formData[question.display_id]) {
              this.formData[question.display_id].forEach((formDataValue, formDataIndex) => {
                if (formDataValue === orderselectvalue.value) {
                  this.counter=this.counter+1;
                  this.counterlist.push(formDataIndex + 1);
                  this.overlay[formDataIndex] = true;
                }
              });
            }
          });
        } else if (question.type === 'single-select-other') {
          this.OthersObj  ={};
          let orderSelectValues = question.values;
          this.otherDisplayId = question.display_id;
          this.otherId = question['other-id'];
          orderSelectValues.forEach(orderselectvalue => {
            if(orderselectvalue.id === this.otherId) {
              this.OthersObj = orderselectvalue;
            }
          });
          if(this.getotherAnswer() != ""){
            this.searchTerm = this.getotherAnswer();
            this.otherText = this.getotherAnswer();
            this.isOther = true;
          } else {
            this.searchTerm = this.getotherAnswer();
            this.otherText = this.getotherAnswer();
            if(this.formData && this.formData[this.otherDisplayId] && this.formData[this.otherDisplayId]?.value ===  this.OthersObj.value ) {
              this.isOther = true;
      
            } else {
              this.isOther = false;
            }
          }
        }
      });
  
  },
};

export default formStep;
