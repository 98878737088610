<template>
    <div>
        <MatchMaking v-if="!isrouteStatev2" />
        <MatchMakingv2 v-else />
    </div>
</template>
<script>
import MatchMaking from "./MatchMaking.vue";
import MatchMakingv2 from "./MatchMakingv2.vue";
export default {
    components: {
        MatchMaking,
        MatchMakingv2
    },
    name: "Home",
    data: () => ({
        isrouteStatev2: false
    }),
    mounted() {
        let firstroute = this.$route.fullPath.toString().split("?")[1];
        let queryParamsArray= firstroute?.split("&")
        if(queryParamsArray){
        queryParamsArray.forEach(query => {
            if(query==='mm_flow=v2'){
            this.isrouteStatev2 = true; 
            }
        });
    }
    }
}
</script>