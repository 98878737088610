<template>
  <span>
  <section class="main_section">
    <!-- :src="https://temp-upload-dev.s3.us-east-2.amazonaws.com/output.html?source=iframe" -->
    <!-- <iframe
      id="iframe"
      :src="published_link"
      style="width: 100%; background: white"
      frameBorder="0"
      v-if="getIsAdvisorProfileOpen"
    /> -->
    <iframe
      id="iframe"
      :src="disclosure_link"
      style="width: 100%; background: white"
      frameBorder="0"
      v-if="getIsAdvisorProfileOpen"
    />

    <v-container v-else class="advisorListing">
      <!-- <v-row>
      <v-col class="mb-5">
        <section class="download-section">
          <span
            ><i> 5 Questions </i> to ask in your first meeting with an
            Advisor</span
          >
          <a href="#" class="download-btn">DOWNLOAD PDF</a>
        </section>
      </v-col>
    </v-row> -->
      <v-row  v-if="getAdvisorsProfile.length > 0">
        <v-col>
          <h1 class="heading mb-sm-5 mt-5">
            We have found your trusted advisors!
          </h1>
        </v-col>
      </v-row>
      <v-row v-if="getAdvisorsProfile.length > 0" class="justify-center">
        <v-col
          cols="12"
          lg="4"
          sm="6"
          v-for="(v, idx) in getAdvisorsProfile"
          :key="idx"
        >
          <section class="advisorProfileSection">
            <div class="head"></div>
            <div class="body">
              <div class="imgBox active">
                <v-avatar height="100px" width="100px" rounded="50px">
                  <img :src="v.profile.picture" />
                </v-avatar>
              </div>
              <div class="details">
                <h5 class="name">{{ getFullName(v) }}</h5>

                <p class="degree">{{ getAddress(v) }}</p>
                <p class="finance" v-if="v.firm && v.firm.firm_name">{{ v.firm.firm_name }}</p>
                <p class="distance">{{ getDistanceInMiles(v) }}
                </p>
               <div class="min-height-chips">
                 <span  v-if="v.endorsements && v.endorsements.adjectives">
                  <v-chip   small class="ma-2 chip-color" v-for="(adjective, index) in v.endorsements.adjectives" :key="index">
                    {{adjective}}</v-chip></span>
                </div>
                <div>
                <v-btn width="100%" small class="bookBtn" @click="
                    bookNow(
                      v.profile.meetings[0].video_confrence + queryParamNylas
                    )
                  " :disabled="
                    v.profile.meetings[0].video_confrence ? false : true
                  ">BOOK NOW</v-btn>
                </div>
                <!-- <div class="ratingView mb-4 mt-2" v-if="Number(v.overall_rating) > 0">
                  <template>
                    <span x-small>Overall Rating  </span>
                    <v-rating
                      class="starView mb-2"
                      :value="Number(v.overall_rating)"
                      background-color="#85BE05"
                      color="#85BE05"
                      half-increments
                      hover
                      small
                    >
                      <template v-slot:item="props">
                        <v-icon
                          background-color="#85be06 lighten-2"
                          class="engine"
                          color="#85be06"
                          :style="{
                            'background-image':
                              'url(' + getImageSrc(props) + ')',
                          }"
                        >
                        </v-icon> </template
                    ></v-rating>
                    <h1>
                      {{ v.overall_rating }}
                    </h1>
                  </template>
                </div>
                <div class="ratingView mb-4 mt-2" v-else>
                  <template>
                    <h6 class="overall_rating">OVERALL RATING PENDING</h6>
                  </template>
                </div> -->
                <div class="serviceBox">
                  <div class="serviceDetails" v-if="v.firm && v.firm.services_provided && get_mm_flow_id!='nxt_r'">
                    <h6>SERVICES PROVIDED</h6>
                    <ul>
                      <li
                        v-for="(serviceProvided, index) in v.firm
                          .services_provided"
                        :key="index"
                      >
                        {{ serviceProvided }}
                      </li>
                    </ul>
                  </div>
                  <div class="serviceDetails" v-if="v.clients && v.clients.professions && get_mm_flow_id!='nxt_r'">
                    <h6>CLIENT SERVED</h6>
                    <ul>
                      <li
                        v-for="(clientServed, index) in v.clients.professions"
                        :key="index"
                      >
                        {{ clientServed.profession_name }}
                      </li>
                    </ul>
                  </div>
                  <div class="serviceDetails reasonDetails" v-if="v.reasons && v.reasons && get_mm_flow_id==='nxt_r'">
                    <h6>REASONS FOR MATCH</h6>
                    <ul>
                      <li
                        v-for="(clientServed, index) in v.reasons"
                        :key="index"
                      >
                        {{ getReasonDetails(clientServed) }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer">
              <!-- <v-btn
                width="100%"
                small
                class="fullProfile"
                @click="viewProfile(v.links.published)"
              >
                FULL PROFILE</v-btn
              > -->
              <v-btn
                width="100%"
                small
                class="fullProfile"
                @click="viewProfile(v.solicitor_disclosure)"
                :disabled="!(v.solicitor_disclosure)"
              >
                DISCLOSURE</v-btn
              >
            </div>
          </section>
        </v-col>
      </v-row>
      <v-row v-else align="center" justify="center" class="mt-2">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-row>
      <v-dialog v-model="showBooking" width="960">
        <div style="position: relative">
          <iframe
            id="iframe"
            :src="booking_link"
            style="height: 635px; width: 100%; background: white"
            frameBorder="0"
          />
          <div class="closeButton">
            <v-icon @click="closeBookingModal()">mdi-close</v-icon>
          </div>
        </div>
      </v-dialog>
    </v-container>
  </section>
  <section v-if="getdebugx && getdebugx!='' && getDebugxData.length>0 && !getIsAdvisorProfileOpen">
    <v-simple-table dense>
      <template v-slot:default >
        <thead>
            <tr>
              <th class="text-center">Request Payload</th>
            </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ apiPayload }}</td>
        </tr>
        <tr v-if="getotherAnswer !=''">
          <td>Others Text : {{ getotherAnswer }}</td>
        </tr>
      </tbody>
      </template>
    </v-simple-table>
    <v-simple-table dense v-for="(v,idx) in getDebugxData" :key="idx">
      <template v-slot:default v-if="idx===0">
        <thead>
          <tr>
              <th class="text-center">Advisor ID</th>
              <th class="text-center">Name</th>
              <th class="text-center">Total Score / Total Weight</th>
              <th class="text-center">Total Score (weighted)[with Reviews , w/o Reviews] </th>
              <th class="text-center">Own Score </th>
              <th class="text-center">Own Score (weighted) [with Reviews , w/o Reviews]</th>
              <th class="text-center">Own Score (weighted + market) [with Reviews , w/o Reviews]</th>
              <th class="text-center">Review Score</th>
              <th class="text-center">Review Score  (weighted)[with Reviews , w/o Reviews]</th>
              <th class="text-center">Advisor Details</th>
             
            </tr>
      </thead>
      <tbody>
        <tr v-if="get_mm_flow_id==='nxt_r'">
          <td></td>
          <td></td>
          <td>{{ total_weigthed_score }}</td>
          <td>{{ total_weigthed_score_r }}</td>
          <td>  
            Motivation: {{ motivation_mm_score_calc }}<br/>
            Motivation1: {{ motivation_1_mm_score_calc }}<br/>
            Life Changes: {{ life_changes_mm_score_calc }}<br/>
            Aum Served: {{ aum_served_mm_score_calc }}<br/>
            Personality: {{ personality_mm_score_calc }}<br/>
            Personal Situation: {{ demographic_mm_score_calc }}<br/>
            Professions: {{ professions_mm_score_calc }}<br/>
            Location: {{nearness_mm_score_calc }}<br/>
          </td>
          <td style="width: 250px;">
            Weight aum served: {{ converArrayValues(weight_aum_served_mm_score_calc )}}<br/>
            Weight demographic: {{converArrayValues(weight_demographic_mm_score_calc) }}<br/>
            Weight life changes: {{ converArrayValues(weight_life_changes_mm_score_calc )}}<br/>
            Weight motivation 1: {{ converArrayValues(weight_motivation_1_mm_score_calc) }}<br/>
            Weight motivation: {{converArrayValues(weight_motivation_mm_score_calc) }}<br/>
            Weight personality: {{ converArrayValues(weight_personality_mm_score_calc) }}<br/>
            Weight professions: {{ converArrayValues(weight_professions_mm_score_calc) }}<br/>
            Weight Location: {{ converArrayValues(weight_nearness_mm_score_calc )}}<br/>
          </td>
          <td>
            Motivation: {{ motivation_mm_mkt }}<br/>
            Motivation1: {{ motivation_1_mm_mkt }}<br/>
            Life Changes: {{ life_changes_mm_mkt }}<br/>
            Aum Served: {{ aum_served_mm_mkt }}<br/>
            Demographic: {{ demographic_mm_mkt }}<br/>
            Nearness: {{ nearness_mm_mkt }}
            Professions: {{ professions_mm_mkt }}<br/>
            Personality: {{ personality_mm_mkt }}<br/>
          </td>
          <td> 
            Investable assets: {{investable_assets_r_mm_score_calc}} <br />
            Life Changes:  {{ life_changes_r_mm_score_calc }}<br />
            Motivation 1:  {{ motivation_1_r_mm_score_calc }} <br />
            Motivation: {{motivation_r_mm_score_calc }}<br />
            Personal Situtation: {{  personal_situation_r_mm_score_calc}}<br />
            Profession:  {{  profession_r_mm_score_calc}}<br />
          </td>
          <td>
            Review Investable assets :{{weight_investable_assets_r_mm_score_calc }}<br/>
            Review Life Changes: {{weight_life_changes_r_mm_score_calc}}<br/>
            Review Motivation 1: {{weight_motivation_1_r_mm_score_calc}}<br/>
            Review Motivation: {{ weight_motivation_r_mm_score_calc}}<br/>
            Review Personal Situtation: {{ weight_personal_situation_r_mm_score_calc }}<br/>
            Review Profession: {{weight_profession_r_mm_score_calc }}<br/>
          </td>
          <td></td>
        </tr>
        <tr
          v-for="item in v"
          :key="item.advisor_id"
        >
          <td>{{ item.advisor_id }}</td>
          <td>{{ item.profile.firstname }}  {{ item.profile.lastname }}</td>
          <td>{{ converArrayValues(item.mm_scores.w_mm_score )}}</td>
          <td>{{ converArrayValues(item.mm_scores.mm_score )}}</td>
          <td style="width: 200px;">
            Motivation: {{item.mm_scores.motivation_mm_score_calc }}<br/>
            Motivation1: {{ item.mm_scores.motivation_1_mm_score_calc }}<br/>
            Life Changes: {{ item.mm_scores.life_changes_mm_score_calc }}<br/>
            Aum Served: {{ item.mm_scores.aum_served_mm_score_calc }}<br/>
            Personality: {{ item.mm_scores.personality_mm_score_calc }}<br/>
            Personal Situation: {{ item.mm_scores.demographic_mm_score_calc }}<br/>
            Professions: {{ item.mm_scores.professions_mm_score_calc }}<br/>
            Location: {{ item.mm_scores.nearness_mm_score_calc }}<br/>
          </td>
          
       
          <td style="width: 250px;">
            Weight aum served: {{ converArrayValues(item.mm_scores.weight_aum_served_mm_score_calc )}}<br/>
            Weight demographic: {{converArrayValues(item.mm_scores.weight_demographic_mm_score_calc) }}<br/>
            Weight life changes: {{ converArrayValues(item.mm_scores.weight_life_changes_mm_score_calc )}}<br/>
            Weight motivation 1: {{ converArrayValues(item.mm_scores.weight_motivation_1_mm_score_calc) }}<br/>
            Weight motivation: {{converArrayValues( item.mm_scores.weight_motivation_mm_score_calc) }}<br/>
            Weight personality: {{ converArrayValues(item.mm_scores.weight_personality_mm_score_calc) }}<br/>
            Weight professions: {{ converArrayValues(item.mm_scores.weight_professions_mm_score_calc) }}<br/>
            Weight Location: {{ converArrayValues(item.mm_scores.weight_nearness_mm_score_calc )}}<br/>
          </td>
          <td style="width: 200px;">
            Motivation: {{ item.mm_scores.motivation_mm_mkt }}<br/>
            Motivation1: {{ item.mm_scores.motivation_1_mm_mkt }}<br/>
            Life Changes: {{ item.mm_scores.life_changes_mm_mkt }}<br/>
            Aum Served: {{ item.mm_scores.aum_served_mm_mkt }}<br/>
            Demographic: {{ item.mm_scores.demographic_mm_mkt }}<br/>
            Nearness: {{ item.mm_scores.nearness_mm_mkt }}
            Professions: {{ item.mm_scores.professions_mm_mkt }}<br/>
            Personality: {{ item.mm_scores.personality_mm_mkt }}<br/>
          </td>
          <td style="width: 200px;">
            Investable assets: {{ item.mm_scores.investable_assets_r_mm_score_calc }}<br/>
            Life Changes: {{ item.mm_scores.life_changes_r_mm_score_calc }}<br/>
            Motivation 1: {{ item.mm_scores.motivation_1_r_mm_score_calc}}<br/>
            Motivation: {{ item.mm_scores.motivation_r_mm_score_calc }}<br/>
            Personal Situtation: {{ item.mm_scores.personal_situation_r_mm_score_calc }}<br/>
            Profession: {{ item.mm_scores.profession_r_mm_score_calc}}<br/>
          </td>
          <td style="width: 250px;">
            Review Investable assets :{{ converArrayValues( item.mm_scores.weight_investable_assets_r_mm_score_calc) }}<br/>
            Review Life Changes: {{ converArrayValues(item.mm_scores.weight_life_changes_r_mm_score_calc) }}<br/>
            Review Motivation 1: {{ converArrayValues(item.mm_scores.weight_motivation_1_r_mm_score_calc) }}<br/>
            Review Motivation: {{ converArrayValues(item.mm_scores.weight_motivation_r_mm_score_calc )}}<br/>
            Review Personal Situtation: {{ converArrayValues(item.mm_scores.weight_personal_situation_r_mm_score_calc) }}<br/>
            Review Profession: {{ converArrayValues(item.mm_scores.weight_profession_r_mm_score_calc) }}<br/>
          </td>
          <td style="width: 200px;">
            Max AUM Served: {{ item.max_aum_served }}<br/>
            Min AUM Served: {{ item.min_aum_served }}<br/>
            Nearness: {{ item.nearness }}<br/>
            Location Zip: {{ item.location.zip }}<br/>
          </td>
        </tr>
      </tbody>
      </template>
    </v-simple-table>
  </section>
</span>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "AdvisorListing",
  data: () => ({
    published_link: "",
    disclosure_link:"",
    booking_link: "",
    showBooking: false,
    ip: '',
    resultListing:[],
    weight_aum_served_mm_score_calc: 0,
    weight_demographic_mm_score_calc:0,
    weight_life_changes_mm_score_calc:0,
    weight_motivation_1_mm_score_calc:0,
    weight_motivation_mm_score_calc:0,
    weight_personality_mm_score_calc:0,
    weight_professions_mm_score_calc:0,
    weight_nearness_mm_score_calc:0,
    total_weigthed_score: 0,
    total_weigthed_score_r: 0,
    motivation_mm_score_calc: 0,
    motivation_1_mm_score_calc:0,
    life_changes_mm_score_calc:0,
    professions_mm_score_calc:0,
    demographic_mm_score_calc:0,
    weight_investable_assets_r_mm_score_calc:0,
    weight_life_changes_r_mm_score_calc:0,
    weight_motivation_1_r_mm_score_calc:0,
    weight_motivation_r_mm_score_calc:0,
    weight_personal_situation_r_mm_score_calc:0,
    weight_profession_r_mm_score_calc:0,
    investable_assets_r_mm_score_calc  :0,
    life_changes_r_mm_score_calc : 0 ,
    motivation_1_r_mm_score_calc  : 0,
    motivation_r_mm_score_calc  : 0,
    personal_situation_r_mm_score_calc  : 0,
    profession_r_mm_score_calc  :0,
    aum_served_mm_score_calc: 0,
    personality_mm_score_calc: 0,
    nearness_mm_score_calc:0,
    motivation_mm_mkt:0,
    motivation_1_mm_mkt:0,
    life_changes_mm_mkt :0,
    aum_served_mm_mkt:0,
    demographic_mm_mkt:0,
    nearness_mm_mkt:0,
    professions_mm_mkt :0,
    personality_mm_mkt: 0,
  }),
  computed: {
    ...mapGetters([
      "getAdvisorsProfile",
      "getdebugx",
      "get_mm_flow_id",
      "getDebugxData",
      "getIsAdvisorProfileOpen",
      "apiPayload",
      "getotherAnswer",
      "getfinalPayload",
      "getUserId",
      "get_mm_flow_id",
      "getQuestions",
      "getAnswers"
    ]),
    queryParamNylas() {
      const data = this.apiPayload;
      const browser =  this.detectBrowser();
      const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
      const referrer = document.referrer === '' ? document.location.origin: document.referrer ; 
      const useragent = navigator.userAgent;
      const cta_id = 'MM_BOOK';
      const { firstName, lastName, email, phone } = data;
      // if (process.env.NODE_ENV === "production") {
        return `?name=${firstName} ${lastName}&email=${email}&phone_number=${Number(
          phone
        )}&user_defined_phone_number=${Number(phone)}&user_id=${this.getUserId}&ip_addr=${this.ip}&browser=${browser}&device=${platform}&referrer=${referrer}&user-agent=${useragent}&cta_id=${cta_id}`;
      //}
      //  return `?name=${firstName} ${lastName}&email=${email}&phone_number=${Number(
      //  phone
      // )}&user_defined_phone_number=${Number(phone)}`;
    },
  },
  methods: {
    ...mapState(["advisorsProfile"]),
    ...mapActions(["toggleIsAdvisorProfileOpen"]),
    getImageSrc(props) {
      return props.isFilled
        ? require("@/assets/image/icon-star-full.svg")
        : props.isHalfFilled
        ? require("@/assets/image/icon-star-half.svg")
        : require("@/assets/image/icon-star-empty.svg");
    },
    getFullName(v) {
      return v.profile.firstname + " " + v.profile.lastname;
    },
    getAddress(v) {
      return v.location.city + ", " + v.location.state;
    },
    getReasonDetails(v) {
      let reason;
      let reasonId;
      let displayId;
      let v_endsWith = v.endsWith("_r");
      if (this.getQuestions && this.getQuestions.length) {
        this.getQuestions?.forEach(query => {

          if (v_endsWith) {
            reasonId = v.slice(0, -2)
          } else {
            reasonId = v
          }
          if (query?.reason?.id === reasonId || query?.display_id === reasonId) {

            reason = query.reason.text;
            displayId = query.display_id

          }
        });
      }
      return this.replaceAnswer(reasonId,displayId, reason);
    },
    replaceAnswer(reasonId,displayId, reason) {
      let answerToReplaceHash;
      if (displayId && this.getAnswers[displayId]) {
        if (this.getAnswers[displayId]?.value) {
          answerToReplaceHash = this.getAnswers[displayId].value;
        } else if (Array.isArray(this.getAnswers[displayId])) {
          answerToReplaceHash = this.getAnswers[displayId]?.join(", ")
        } else {
          answerToReplaceHash = this.getAnswers[displayId]
        }

        reason = reason.replace("###", answerToReplaceHash)
      } else if  (reasonId && this.getAnswers[reasonId]) {
        if (this.getAnswers[displayId]?.value) {
          answerToReplaceHash = this.getAnswers[reasonId].value;
        } else if (Array.isArray(this.getAnswers[reasonId])) {
          answerToReplaceHash = this.getAnswers[reasonId]?.join(", ")
        } else {
          answerToReplaceHash = this.getAnswers[reasonId]
        }
        reason = reason.replace("###", answerToReplaceHash)
      }
      return reason;
    },
   /* viewProfile(src) {
      this.published_link = `${process.env.VUE_APP_PUBLISHED_LINK}/${src}?source=iframe`;
      if (process.env.NODE_ENV === "production") {
        this.published_link =
          this.published_link +
          "&" +
          this.queryParamNylas.slice(1, this.queryParamNylas.length);
      }
      this.toggleIsAdvisorProfileOpen();
    }, */
     viewProfile(src) {
      //  this.disclosure_link = `${process.env.VUE_APP_DISCLOSURE_AGREEMENT_LINK}/${src}?source=iframe`;
       this.disclosure_link = `${src}?source=iframe`;
      //  if (process.env.NODE_ENV === "production") {
      //    console.log("inside if condition")
      //    this.disclosure_link =
      //      this.disclosure_link +
      //      "&" +
      //      this.queryParamNylas.slice(1, this.queryParamNylas.length);
      //  }
       this.toggleIsAdvisorProfileOpen();
     },
    closeBookingModal() {
      this.showBooking = false;
      this.booking_link = "";
    },
    bookNow(src) {
      this.booking_link = src;
      this.showBooking = true;
      this.googleTagManagerDataLayer("onclick", "book_now");
    },
    getDistanceInMiles(v) {
      const { nearness } = v;
      let nearnesslower = nearness.toLowerCase();
      if (nearnesslower === "remote" || nearnesslower === 'far') {
        return "Able to work virtually";
      }
      if (nearnesslower === "nearby") {
        return `less than 10 miles away`;
      }
      if (nearnesslower === "local") {
        return `less than 30 miles away`;
      }
      if (nearnesslower === "regional") {
        return `less than 250 miles away`;
      }
    },
    googleTagManagerDataLayer(actionType, eventName) {
      this.$gtm.trackEvent({
        action: actionType,
        event: "prod.matchmaking." + eventName,
        virtual_page_url: `https://${window.location.host}/virtual/${eventName}`
        
      });
    },
    getUserIP() {
      fetch('https://api.ipify.org?format=json')
        .then(x => x.json())
        .then(({ ip }) => {
          this.ip = ip;
      });
    },
    detectBrowser() { 
      if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
          return 'Opera';
      } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
          return 'Chrome';
      } else if(navigator.userAgent.indexOf("Safari") != -1) {
          return 'Safari';
      } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
          return 'Firefox';
      } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
          return 'IE';
      } else {
          return 'Unknown';
      }
    },
    converArrayValues(sampleArray){
      if (Array.isArray(sampleArray)) {
        sampleArray = sampleArray.map(function (each_element) {
          return Number(each_element.toFixed(2));
        });
      }
      return sampleArray;
    }
  },
  mounted() {
    this.googleTagManagerDataLayer("onload", "matches");
    this.getUserIP();
    this.resultListing = this.getDebugxData[0];
    this.investable_assets_r_mm_score_calc  = 0;
    this.life_changes_r_mm_score_calc = 0 ;
    this.motivation_1_r_mm_score_calc  = 0;
    this.motivation_r_mm_score_calc  = 0;
    this.personal_situation_r_mm_score_calc  = 0;
    this.profession_r_mm_score_calc  = 0;
    this.motivation_mm_mkt = 0;
    this.motivation_1_mm_mkt = 0;
    this.life_changes_mm_mkt = 0;
    this.aum_served_mm_mkt = 0;
    this.demographic_mm_mkt = 0;
    this.nearness_mm_mkt = 0;
    this.professions_mm_mkt = 0;
    this.personality_mm_mkt =0 ;
    this.total_weigthed_score = 0;
    this.total_weigthed_score_r = 0;
    this.aum_served_mm_score_calc = 0;
    this.personality_mm_score_calc = 0;
    this.demographic_mm_score_calc = 0;
   if(this.get_mm_flow_id==='nxt_r') {
      this.resultListing?.forEach(query => {
        this.total_weigthed_score =  this.total_weigthed_score + query.mm_scores.w_mm_score[0];
        this.total_weigthed_score_r =  this.total_weigthed_score_r + query.mm_scores.mm_score[0];
        this.investable_assets_r_mm_score_calc = (this.investable_assets_r_mm_score_calc +  query.mm_scores.investable_assets_r_mm_score_calc) ;
        this.life_changes_r_mm_score_calc = (this.life_changes_r_mm_score_calc +  query.mm_scores.life_changes_r_mm_score_calc) ;
        this.motivation_1_r_mm_score_calc = (this.motivation_1_r_mm_score_calc +  query.mm_scores.motivation_1_r_mm_score_calc) ;
        this.motivation_r_mm_score_calc = (this.motivation_r_mm_score_calc +  query.mm_scores.motivation_r_mm_score_calc) ;
        this.personal_situation_r_mm_score_calc = (this.personal_situation_r_mm_score_calc +  query.mm_scores.personal_situation_r_mm_score_calc) ;
        this.profession_r_mm_score_calc = (this.profession_r_mm_score_calc +  query.mm_scores.profession_r_mm_score_calc) ;
        this.motivation_mm_mkt = (this.motivation_mm_mkt + query.mm_scores.motivation_mm_mkt[0]) ;
        this.motivation_1_mm_mkt =(this.motivation_1_mm_mkt + query.mm_scores.motivation_1_mm_mkt[0]) ;
        this.life_changes_mm_mkt = (this.life_changes_mm_mkt +query.mm_scores.life_changes_mm_mkt[0]) ;
        this.aum_served_mm_mkt = (this.aum_served_mm_mkt + query.mm_scores.aum_served_mm_mkt[0]); 
        this.demographic_mm_mkt = (this.demographic_mm_mkt + query.mm_scores.demographic_mm_mkt[0]);
        this.nearness_mm_mkt = (this.nearness_mm_mkt + query.mm_scores.nearness_mm_mkt[0]);
        this.professions_mm_mkt = (this.professions_mm_mkt +query.mm_scores.professions_mm_mkt[0]);
        this.personality_mm_mkt =(this.personality_mm_mkt +query.mm_scores.personality_mm_mkt[0]);
        this.motivation_mm_score_calc = this.motivation_mm_score_calc + query.mm_scores.motivation_mm_score_calc;
        this.motivation_1_mm_score_calc = this.motivation_1_mm_score_calc + query.mm_scores.motivation_1_mm_score_calc;
        this.life_changes_mm_score_calc = this.life_changes_mm_score_calc + query.mm_scores.life_changes_mm_score_calc;
        this.aum_served_mm_score_calc = this.aum_served_mm_score_calc + query.mm_scores.aum_served_mm_score_calc;
        this.personality_mm_score_calc = this.personality_mm_score_calc + query.mm_scores.personality_mm_score_calc;
        this.demographic_mm_score_calc = this.demographic_mm_score_calc + query.mm_scores.demographic_mm_score_calc;
        this.professions_mm_score_calc = this.professions_mm_score_calc +query.mm_scores.professions_mm_score_calc;
        this.nearness_mm_score_calc = this.nearness_mm_score_calc +query.mm_scores.nearness_mm_score_calc;
        this.weight_aum_served_mm_score_calc =  this.weight_aum_served_mm_score_calc  + query.mm_scores.weight_aum_served_mm_score_calc[0];
        this.weight_demographic_mm_score_calc =  this.weight_demographic_mm_score_calc + query.mm_scores.weight_demographic_mm_score_calc[0];
        this.weight_life_changes_mm_score_calc =  this.weight_life_changes_mm_score_calc + query.mm_scores.weight_life_changes_mm_score_calc[0];
        this.weight_motivation_1_mm_score_calc =  this.weight_motivation_1_mm_score_calc + query.mm_scores.weight_motivation_1_mm_score_calc[0];
        this.weight_motivation_mm_score_calc =  this.weight_motivation_mm_score_calc + query.mm_scores.weight_motivation_mm_score_calc[0];
        this.weight_personality_mm_score_calc = this.weight_personality_mm_score_calc + query.mm_scores.weight_personality_mm_score_calc[0];
        this.weight_professions_mm_score_calc =  this.weight_professions_mm_score_calc +query.mm_scores.weight_professions_mm_score_calc[0];
        this.weight_nearness_mm_score_calc = this.weight_nearness_mm_score_calc + query.mm_scores.weight_nearness_mm_score_calc[0];
        this.weight_investable_assets_r_mm_score_calc =this.weight_investable_assets_r_mm_score_calc + query.mm_scores.weight_investable_assets_r_mm_score_calc[0];
        this.weight_life_changes_r_mm_score_calc=this.weight_life_changes_r_mm_score_calc+query.mm_scores.weight_life_changes_r_mm_score_calc[0];
        this.weight_motivation_1_r_mm_score_calc=this.weight_motivation_1_r_mm_score_calc+query.mm_scores.weight_motivation_1_r_mm_score_calc[0];
        this.weight_motivation_r_mm_score_calc =this.weight_motivation_r_mm_score_calc +query.mm_scores.weight_motivation_r_mm_score_calc[0];
        this.weight_personal_situation_r_mm_score_calc =this.weight_personal_situation_r_mm_score_calc +query.mm_scores.weight_personal_situation_r_mm_score_calc[0] ;
        this.weight_profession_r_mm_score_calc = this.weight_profession_r_mm_score_calc +query.mm_scores.weight_profession_r_mm_score_calc[0] ;
      });
        this.weight_investable_assets_r_mm_score_calc =this.weight_investable_assets_r_mm_score_calc.toFixed(2);
        this.weight_life_changes_r_mm_score_calc= this.weight_life_changes_r_mm_score_calc.toFixed(2);
        this.weight_motivation_1_r_mm_score_calc=this.weight_motivation_1_r_mm_score_calc.toFixed(2);
        this.weight_motivation_r_mm_score_calc =this.weight_motivation_r_mm_score_calc.toFixed(2);
        this.weight_personal_situation_r_mm_score_calc =this.weight_personal_situation_r_mm_score_calc.toFixed(2) ;
        this.weight_profession_r_mm_score_calc =  this.weight_profession_r_mm_score_calc.toFixed(2) ;
        this.weight_aum_served_mm_score_calc =  this.weight_aum_served_mm_score_calc.toFixed(2);
        this.weight_demographic_mm_score_calc =  this.weight_demographic_mm_score_calc.toFixed(2);
        this.weight_life_changes_mm_score_calc =  this.weight_life_changes_mm_score_calc.toFixed(2);
        this.weight_motivation_1_mm_score_calc =  this.weight_motivation_1_mm_score_calc.toFixed(2);
        this.weight_motivation_mm_score_calc =  this.weight_motivation_mm_score_calc.toFixed(2);
        this.weight_personality_mm_score_calc = this.weight_personality_mm_score_calc.toFixed(2);
        this.weight_professions_mm_score_calc =  this.weight_professions_mm_score_calc.toFixed(2);
        this.weight_nearness_mm_score_calc = this.weight_nearness_mm_score_calc.toFixed(2);
        this.nearness_mm_score_calc = this.nearness_mm_score_calc.toFixed(2);
        this.professions_mm_score_calc = this.professions_mm_score_calc.toFixed(2);
        this.motivation_mm_score_calc = this.motivation_mm_score_calc.toFixed(2);
        this.motivation_1_mm_score_calc = this.motivation_1_mm_score_calc.toFixed(2);
        this.life_changes_mm_score_calc =  this.life_changes_mm_score_calc.toFixed(2);
        this.demographic_mm_score_calc = this.demographic_mm_score_calc.toFixed(2);
        this.personality_mm_score_calc = this.personality_mm_score_calc.toFixed(2);
        this.aum_served_mm_score_calc = this.aum_served_mm_score_calc.toFixed(2);
        this.total_weigthed_score = this.total_weigthed_score.toFixed(2);
        this.total_weigthed_score_r = this.total_weigthed_score_r.toFixed(2);      this.investable_assets_r_mm_score_calc =  this.investable_assets_r_mm_score_calc.toFixed(2)  ;
        this.life_changes_r_mm_score_calc =this.life_changes_r_mm_score_calc.toFixed(2) ;
        this.motivation_1_r_mm_score_calc =  this.motivation_1_r_mm_score_calc.toFixed(2) ;
        this.motivation_r_mm_score_calc =this.motivation_r_mm_score_calc.toFixed(2) ;
        this.personal_situation_r_mm_score_calc = this.personal_situation_r_mm_score_calc.toFixed(2) ;
        this.profession_r_mm_score_calc = this.profession_r_mm_score_calc.toFixed(2)  ;
        this.motivation_mm_mkt =  this.motivation_mm_mkt.toFixed(2) ;
        this.motivation_1_mm_mkt =  this.motivation_1_mm_mkt.toFixed(2) ; 
        this.life_changes_mm_mkt = this.life_changes_mm_mkt.toFixed(2) ;
        this.aum_served_mm_mkt =  this.aum_served_mm_mkt.toFixed(2); 
        this.demographic_mm_mkt =  this.demographic_mm_mkt.toFixed(2);
        this.nearness_mm_mkt =  this.nearness_mm_mkt.toFixed(2);
        this.professions_mm_mkt =this.professions_mm_mkt.toFixed(2);
        this.personality_mm_mkt = this.personality_mm_mkt.toFixed(2);
    }
  },
};
</script>
<style lang="scss">
$primary-color: #1c89e6;
$secondary-color: #092d4c;
html {
  overflow-y: auto;
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  display: none;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  height: 30px;
  background-color: transparent;
}
::-webkit-scrollbar-track-piece {
  background-color: #3b3b3b;
  border-radius: 16px;
  -webkit-border-radius: 16px;
}
::-webkit-scrollbar-thumb:vertical {
  height: 50px;
  background-color: #666;
  border: 1px solid #eee;
  border-radius: 6px;
  -webkit-border-radius: 6px;
}
.v-dialog {
  box-shadow: none !important;
  .closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9;
    cursor: pointer;
  }
}
.v-overlay__scrim {
  background-color: #dadada !important;
}
.heading {
  color: $secondary-color !important;
  font-size: 28px;
  text-align: center !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -1px;
}
.download-section {
  background: #ffffff;
  border: 1px solid #dde4e9;
  border-radius: 10px;
  padding: 15px;
  span {
    color: #092d4c;
    i {
      font-weight: 100 !important;
      opacity: 0.6;
    }
  }
  .download-btn {
    color: #1f99ff;
    border: 1px solid #1f99ff;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 4px;
    margin-left: 20px;
    text-decoration: none;
    font-size: 12px;
  }
}
.container {
  max-width: 1035px !important;
}
.advisorProfileSection {
  border: 1px solid #edf1f5;
  border-radius: 20px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 35px 0 #edf1f5;
  $primary-color: #1f99ff;
  max-width: 320px;
  margin: 0 auto;
  .head {
    height: 60px;
    background: $primary-color url("../assets/image/advisor-head-bg.svg");
    background-position: right;
    background-repeat: no-repeat;
  }
  .body {
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    .imgBox {
      width: 100px;
      height: 100px;
      margin: 0 auto;
      margin-top: -50px;
      border-radius: 50%;
      background: #ccc;
      &.active {
        position: relative;
        &::before {
          content: "";
          height: 25px;
          width: 25px;
          position: absolute;
          background: #85be05 url("../assets/image/tick.svg");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          z-index: 1;
          border-radius: 50%;
          left: 0px;
          top: 5px;
        }
      }
    }
    .details {
      padding: 0 15px 0;
      display: flex;
      flex-direction: column;
      flex: 1;
      .name {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        /* identical to box height */

        text-align: center;

        color: #092d4c;
        margin: 0;
        margin-top: 10px;
        font-family: "Montserrat", sans-serif;
      }
      .degree {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */
        color: #092d4c;
        margin: 0;
        font-family: "Open Sans", sans-serif;
      }
      .finance {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #818d98;
        margin: 0;
        font-family: "Open Sans", sans-serif;
      }
      .distance {
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        text-align: center;
        color: #818d98;
        margin: 5px;
        font-family: "Open Sans", sans-serif;
      }
      .bookBtn {
        margin: 10px 0;
        background: #85be05;
        color: #fff;
        font-size: 12px;
        height: 38px;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        .v-btn__content {
          color: #fff;
        }
      }
      .ratingView {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 15px;
        justify-content: space-around;
        padding-left: 1rem;
        padding-right: 1rem;
      }
      .ratingView > span {
        font-size: small;
        font-weight: 600;
      }
      .ratingView .v-rating > button {
        padding: 0 0 5px 0;
        margin: 1px;
      }
      .fullProfile {
        margin: 0;
        background: transparent;
        color: #818d98;
        font-size: 12px;
        border: 1px solid #818d98;
      }
      .serviceBox {
        .reasonDetails {
          ul {
            li {
              width:100% !important
            }
          }
        }

      }
      .serviceBox {
        display: flex;
        flex-direction: column;
        flex: 1;
        .serviceDetails {
          background: #f4f7fa;
          border: 4px;
          color: #818d98;
          padding: 10px;
          margin-bottom: 10px;
          flex: 1;
          border-radius: 10px;
          h6 {
            font-size: 10px;
            font-weight: 600;
          }
          ul {
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
            padding-left: 10px;
            li {
              width: 50%;
              padding: 2px 10px;
              font-size: 11px;
              text-align: left;
              list-style-type: none;
              position: relative;
              overflow-wrap: break-word;
              font-weight: 400;
              &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 8px;
                background: #aaa;
                border-radius: 50%;
                height: 4px;
                width: 4px;
              }
            }
          }
        }
      }
    }
    .chip-color {
      color: #818d98;
      font-size: 10px;
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
    }
    .min-height-chips {
      min-height: 40px;
    }
  }
  .footer {
    padding: 0 15px 15px;
    .theme--light.v-btn.v-btn--has-bg {
      background: none;
      border: 1px solid #818d98;
      color: #818d98;
      height: 36px;
      .v-btn__content {
        font-weight: 700;
        font-family: "Montserrat", sans-serif;
        letter-spacing: 0px;
      }
    }
  }
}
.main_section {
  background: #fff;
}
iframe {
  height: calc(100vh - 100px);
}
.overall_rating {
  font-size: 10px;
  font-weight: 600;
  color: #FF9F00
}
</style>
