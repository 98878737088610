<template>
  <section class="content-660" v-if="getQuestions && (currentStep <= getQuestions.length-2) " >
    <ValidationObserver v-slot="{ handleSubmit }"  :key="obj.id" v-for="(obj, index) in QuestionWithoutQB" >
      <form @keydown.enter.prevent="" @submit.prevent="handleSubmit(currentTab(obj.display_id,obj.type , obj.gtm_value , obj.display_type))" v-if="index === currentStep">
        <ValidationProvider
          v-slot="{ errors }"
          :rules="obj.type === 'multi-select-0' ? '' : 'required'"
          :name="obj.display_id"
        >
          <div class="error-container text-center text-sm-left">
            <span class="text-error">{{ errors[0] }}</span>
          </div>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12 ">
                  <h1 class="heading text-md-left mb-3">
                    {{obj.text}}
                  </h1>
                </v-col>
              </v-row>

              <v-btn-toggle multiple v-model="formData[obj.display_id]" v-if="obj.type==='multi-select' && obj.display_type==='buttons'">
                <v-row class="_10px p-xs-1" justify="center">
                  <v-col
                    v-for="(value, index) in obj.values"
                    :key="index"
                    cols="6"
                    sm="6"
                    class="p-1 custom_col_500"
                  >
                    <v-btn
                      width="100%"
                      :height="obj.values.length>4 ? '50px' : '70px'"
                      outlined
                      tile
                      :value="value.value"
                      @click="submitData"
                    >
                      <span>{{ value.value }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-btn-toggle>
              <v-btn-toggle multiple v-model="formData[obj.display_id]" v-if="obj.type==='order-select' && obj.display_type==='buttons'">
                <v-row class="_10px p-xs-1" justify="center">
                  <v-col v-for="(value, index) in obj.values" :key="index" cols="6" sm="6" class="p-1 custom_col_500">
                    <v-btn width="100%" height="90" :value="value.value"  
                      outlined
                      tile 
                      :disabled =overlay[index]
                      class="min-button min-button-content" @click="submitCard(index)" >
                      <v-row justify="center">
                        <p color="success"  :class="getScreenType === 'mobile' ? 'mt-2' : 'mt-6'" >
                          {{ value.value }}
                        </p>
                        <v-overlay :absolute="true" :value="overlay[index]" class="my-overlay"
                        :class="getScreenType === 'mobile' ? 'mobile-overlay' : 'my-overlay'">
                          {{counterlist[index]}}
                        </v-overlay>
                      </v-row>
                      </v-btn>
                  </v-col>
                </v-row>
              </v-btn-toggle>
              <v-btn-toggle multiple v-model="formData[obj.display_id]" v-if="obj.type==='multi-select-0' && obj.display_type==='buttons'">
                <v-row class="_10px p-xs-1" justify="center">
                  <v-col
                    v-for="(value, index) in obj.values"
                    :key="index"
                    cols="6"
                    sm="6"
                    class="p-1 custom_col_500"
                  ><v-btn
                      width="100%"
                      :height="obj.values.length > 4 ? '50px' : '70px'"
                      outlined
                      tile
                      :value="value.value"
                      @click="submitData">
                    <span>{{ value.value }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-btn-toggle>
              <v-btn-toggle  v-model="formData[obj.display_id]" v-if="obj.type==='single-select' && obj.display_type==='buttons'">
                <v-row class="_10px p-xs-1" justify="center">
                  <v-col
                    v-for="(value, index) in obj.values"
                    :key="index"
                    cols="6"
                    sm="6"
                    :class="obj.singleRowMobile ? 'p1 custom_col' : 'p1'"
                  >
                    <v-btn
                      width="100%"
                      :height="obj.values.length>4 ? '50px' : '70px'"
                      outlined
                      tile
                      :value="value.value"
                      @click="submitData"
                    >
                      <span>{{ value.value }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-btn-toggle>
             
              <v-col cols="12" v-if="obj.type==='single-select' && obj.display_type==='drop-down'">
                <v-autocomplete
                  v-model="formData[obj.display_id]"
                  :items="obj.values"
                  item-text="value"
                  return-object
                  placeholder="Select from the list"
                  solo
                  clearable
                  @input="submitData"
                  height="40px"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" v-if="obj.type==='single-select-other'">
                <v-select @keydown.enter.prevent="" @input="submitDataOther(obj.type, obj.display_id)" @click="clickProfession()" @focus="clickProfession()" item-text="value" return-object v-model="formData.profession" :items="items" attach >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field v-model="searchTerm" ref="searchTextRef" placeholder="Search"  @input="searchProfessions(obj.display_id)"></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col>
              <v-col v-if="obj.type==='text' && obj.display_type==='form' && obj.display_id==='zip_code'">
                <v-text-field
                  v-model="formData.zip_code"
                  :placeholder="obj.place_holder"
                  @keypress="checkKeyPress"
                  solo
                  :loading="loadingStatus"
                  @input="submitZipCode"
                  :error-messages="errorsZip[indexErrors]"
                ></v-text-field>
              </v-col>
              <v-row class="p-xs-1 _10-sm-px" v-if="obj.additional_details && obj.additional_details.display_type==='text_area'">
                <v-col class="p-1">
                  <v-textarea
                    name="textarea"
                    v-model="formData.additionalDetail"
                    auto-grow
                    outlined
                    :height="getScreenType === 'mobile' ? '57px' : '100px'"
                    :class="errors[0] ? 'error--text' : ''"
                    @mouseout="submitData"
                    :placeholder="obj.additional_details.place_holder"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="isOther && obj.type==='single-select-other'">
            <v-col>
              <div :class="getScreenType === 'mobile' ? 'text-center font-bold' : 'please_specify font-bold'">Please Specify</div>
              <v-text-field style=" padding: 0 13px 0 13px;" v-model="otherText"></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="end" no-gutters>
            <v-col class="left"  v-if="currentStep>0">
                <span @click="getPrev(obj.display_id)" class="action" text>
                  <v-img
                    max-width="12"
                    class="mr-2"
                    src="@/assets/image/arrow-left.svg"
                  />
                  BACK</span
                >
            </v-col>
            <v-col :class="getScreenType === 'mobile' ? 'text-right mr-2' : 'text-right'" v-if="obj.type==='order-select'">
                  <v-btn class="secondary action" type="button" @click="reset(obj.display_id)"
                    >RESET
                  </v-btn>
            </v-col>
            <v-col class="text-right" v-if="obj.type==='multi-select-0' && ((formData[obj.display_id] && formData[obj.display_id].length===0)|| !formData[obj.display_id])">
              <v-btn class="secondary action" type="submit"  
              :disabled="buttonStatus"
                >
              
                SKIP
                <v-img
                  max-width="12"
                  class="ml-2"
                  src="@/assets/image/arrow-right.svg"
                />
              </v-btn>
            </v-col>
            <v-col class="text-right" v-else>
              <v-btn class="secondary action" type="submit"  
              :disabled="(obj.type==='order-select' && ((formData[obj.display_id] && formData[obj.display_id].length<4 ))) || buttonStatus"
                >
              
                NEXT
                <v-img
                  max-width="12"
                  class="ml-2"
                  src="@/assets/image/arrow-right.svg"
                />
              </v-btn>
            </v-col>
          </v-row>
        </ValidationProvider>
      </form>
    </ValidationObserver>
  </section>
</template>
  
<script>
  import { mapGetters, mapActions } from "vuex";
  import formStep from "../../mixins/formStep";
  import { bus } from "../../router/bus";
  export default {
    name: "GenericComponent",
    mixins: [formStep],
    mounted() {
      console.log('generic comp')

    this.QuestionWithoutQB?.forEach(question => {
      if (question.type === 'single-select-other') {
        this.items = question.values;
      }
    });
    this.itemsCopy = [...this.items];
    this.$watch(
        function () {
          return this.formData?.zip_code?.length;
        },
        function () {
        
        },
        
      );
      this.addWatcher();
      let firstroute =  window.location.href.toString().split("?")[1];
      let queryParamsArray= firstroute?.split("&")
        if(queryParamsArray){
        queryParamsArray.forEach(query => {
           if(query.split("=")[0]==='zip'){
             console.log(query.split("=")[1])
            this.formData.zip_code = query.split("=")[1];
           }
        });
      }
    },
    
    data: () => ({
      isOther: false,
      searchTerm: "",
      otherText:"",
      itemsCopy: [],
      items: [],
      otherId : '',
      otherDisplayId: '',
      OthersObj: {},
      formDataObj:{},
      absolute: true,
      overlay: {0:false, 1:false,2:false,3:false},
      counter: 0,
      counterlist:[],
      formData: {
      },
      startOver:false,
      loadingStatus: false,
      errorsZip: ["", "", "Enter Valid Zip Code"],
      isValid: false,
      indexErrors: 0,
      buttonStatus: false,
      query_dict: {},
      QuestionWithoutQB:[]

    }),
    computed: {
      ...mapGetters(["getScreenType", "getQuestions", "currentStep"]),
    },
    methods: {
      ...mapActions([
      "loadQuestionBank",
      "checkZipCode"
      ]),
      clickProfession() {
        if (this.$refs && this.$refs['searchTextRef']  && this.otherText==="") {
          if(this.$refs['searchTextRef'][0]?.$refs)
            setTimeout(() => {  this.$refs['searchTextRef'][0].$refs.input.focus()},500);
            else
            setTimeout(() => {  this.$refs['searchTextRef'][0].focus()},500);
        } else {
          setTimeout(() => {  this.$refs['searchTextRef'][0].$refs.input.focus()},500);
        }
      },
      searchProfessions(display_id) {
        this.otherText = this.searchTerm;
        this.items = this.itemsCopy.filter((item) => {
         return (item.value.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 || item.value  === this.OthersObj.value || item.value === this.formData[display_id]?.value);
        });
      },
      reset(display_id) {
        this.overlay= {0:false, 1:false,2:false,3:false};
        this.counter =0;
        this.counterlist =[];
        this.formData[display_id] = [];
        this.submitData();
        this.getNext(this.currentStep)

      },
      submitCard(index) {
        if(this.overlay[index] === false) {
          this.counter=this.counter+1;
          this.counterlist[index]=this.counter;
          this.overlay[index] = true;
          this.submitData();
          this.getNext(this.currentStep)
        }
      },
      addWatcher(){
        this.$watch(
          "currentStep",
          (value) => {
            bus.$on("startOver", (val) => {
              this.startOver = val;
              if(val===true) {
                this.searchTerm = "";
                this.otherText = "";
                this.isOther = false;
                this.items = this.itemsCopy;
                this.updateOtherAnswer(this.searchTerm );
              }
           
            });
            if(value===0 && this.startOver===true){
              this.searchTerm = "";
              this.otherText = "";
              this.isOther = false;
              this.updateOtherAnswer(this.searchTerm );
              this.formData= {};
              this.overlay= {0:false, 1:false,2:false,3:false};
              this.counter = 0;
              this.counterlist = [];
              bus.$emit("startOver",false);
            }
          },
          { immediate: true, deep: true }
        );
      },
      currentTab(key, type, gtm_values, display_type) {
        if(type==='multi-select') {
        if(this.formData[key] && (this.formData[key]?.length))
          this.getNext(this.currentStep+1)
        } else if(key==='zip_code') {
          if (!this.formData.zip_code) {
            this.indexErrors = 1;
          } else if (this.formData.zip_code.length < 5) {
            this.indexErrors = 2;
          } else if (this.formData.zip_code.length === 5) {
            if (this.isValid) {
              this.googleTagManagerDataLayerZipCode();
              this.getNext(this.currentStep+1);
            } else {
              this.checkFromApi();
            }
          }
        } else if(type==='multi-select-0') {
          this.getNext(this.currentStep+1)
        } else if(type==='order-select') {
          if(this.counter === 4)
          this.getNext(this.currentStep+1)
        }
        else {
          if(this.formData[key] && (this.formData[key]?.length || Object.keys(this.formData[key])))
          this.getNext(this.currentStep+1)
        } 
        this.updateOtherAnswer(this.otherText);
        if(key!='zip_code')
        this.googleTagManagerDataLayer(gtm_values, key, display_type);
      },
      getPrev(id) {
        this.getNext(this.currentStep-1, id);
        this.buttonStatus = false;
        this.isValid = false;
      },
      submitDataOther(type, display_id) {
       if (this.formData[display_id].value === this.OthersObj.value && type === 'single-select-other') {
          this.otherText = this.searchTerm;
          this.isOther = true;
          this.updateOtherAnswer(this.otherText)
        } else {
          this.searchTerm = "";
          this.otherText = ""
          this.isOther = false;
          this.updateOtherAnswer(this.otherText)
        }
        if (!this.searchTerm || this.searchTerm === "") {
          this.items = this.itemsCopy;
        }
        this.updateAnswer(this.formData);
      },
      submitData() {
       
        this.updateAnswer(this.formData);
      },
      submitZipCode() {
        if (this.formData?.zip_code?.length === 5) {
          this.indexErrors = 0;
          this.loadingStatus = true;
          this.buttonStatus = true;
          this.checkZipCode(this.formData?.zip_code).then((res) => {
            if (res.status === 200) {
              this.loadingStatus = false;
              if (!res.data.valid_zip) {
                this.indexErrors = 2;
              } else {
                this.isValid = true;
                this.buttonStatus = false;
              }
            }
          });
        } else {
          if (this.formData?.zip_code?.length > 5){ 
          this.indexErrors = 2;
          } else {
          this.indexErrors = 1;
          } 
          this.buttonStatus = true;
        }
        this.updateAnswer(this.formData);       
      },
      googleTagManagerDataLayerZipCode() {
        this.$gtm.trackEvent({
          action: "click",
          event: "mm_zip",
          zip_code: this.formData?.zip_code,
          custom_uuid:this.userId,
          virtual_page_url: `https://${window.location.host}/virtual/zipcode`,
        });
      },
      googleTagManagerDataLayer(gtm_values, key, display_type) {
        let gtmEvent = {
          action: "click",
          event: gtm_values.gtm_id,
          custom_uuid: this.userId,
          virtual_page_url: `https://${window.location.host}/virtual/topics`,
        };
        if(display_type === 'drop-down') {
          gtmEvent[gtm_values.gtm_param]= this.formData[key]?.value;
        } else {
          gtmEvent[gtm_values.gtm_param]= this.formData[key];
        }
        this.$gtm.trackEvent(gtmEvent);
      },
      formSubmit() {
        if (!this.formData?.zip_code) {
          this.indexErrors = 1;
        } else if (this.formData?.zip_code?.length < 5) {
          this.indexErrors = 2;
        } else if (this.formData?.zip_code?.length === 5) {
          if (this.isValid) {
            this.googleTagManagerDataLayerZipCode();
            this.getNext(5);
          } else {
            this.checkFromApi();
          }
        }
    },
    checkKeyPress(e) {
      if (
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        e.keyCode === 8 ||
        e.keyCode === 9
      ) {
        this.indexErrors = 0;
      //  this.buttonStatus = false;
        if (this.formData?.zip_code?.length === 5) {
          if (!(e.keyCode === 8 || e.keyCode === 9)) {
            e.preventDefault();
          }
        }
      } else e.preventDefault();
    },
    checkFromApi() {
      if (this.formData?.zip_code?.length === 5) {
        this.loadingStatus = true;
        this.buttonStatus = true;
        this.checkZipCode(this.formData?.zip_code).then((res) => {
          if (res.status === 200) {
            this.loadingStatus = false;
            if (!res.data.valid_zip) {
              this.indexErrors = 2;
            } else {
              this.isValid = true;
              this.buttonStatus = false;
            }
          }
        });
      }
    },
    },
    beforeMount() {
      this.QuestionWithoutQB = this.getQuestions.filter(question => question.display_id != 'contact_detail');
      if (window.location.href.toString().includes("?")) {
        this.isQueryParam = window.location.href.toString().split("?")[1];
        this.isQueryParam.split("&").map((data) => {
          this.query_dict[data.split("=")[0]] = data.split("=")[1];
        });
      }
      this.query_dict["landing_page"] = "homepage";
      this.$gtm.trackEvent({
        action: "start",
        event: "mm_funnel_start",
        queryParam: this.query_dict,
        custom_uuid: this.userId,
        virtual_page_url: `https://${window.location.host}/virtual/start`,
      });
    },
  };
  </script>
<style lang="scss" scoped>
  .please_specify {
    text-align: left;
  }
  .font-bold {
    font-weight: 600;
  }
  $primary-color: #1c89e6;
  $secondary-color: #092d4c;
  .v-btn-toggle {
    display: block !important;
  }
  .theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
    background: unset;
  }
  // button.v-btn--active .v-btn__content{
  //   color: #818d98 !important;
  // }
  // .v-overlay__scrim{
  //   background-color: unset !important;
  // }
  @media (max-width: 500px) {
    .v-btn.action {
      width: 100% !important;
    }
    .custom_col_500 {
      flex: 0 0 100% !important;
      max-width: 100%!important;
    }
  }
  @media (max-width: 1024px) {
    .custom_col {
      flex: 0 0 100% !important;
      max-width: 100%!important;
    }
  }
  </style>
<style lang="sass" scoped>
  .my-overlay ::v-deep.v-overlay__content 
   font-size: 70px
   font-weight: bold
   color:#989393
  .my-overlay ::v-deep.v-overlay__scrim 
   background-color: unset !important
  .mobile-overlay ::v-deep.v-overlay__content 
    font-size: 35px
    font-weight: bold
    color:#989393

 </style>